<template>
    <form @submit.prevent="sendForm">
        
        <div class="mb-5">
            <label class="block text-sm font-medium text-gray-700">Give your form a title</label>
            <div class="mt-1">
                <input type="text"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" maxlength="255" v-model="form.title">
                <div class="mt-2 text-red-500" v-if="form.errors.has('title')" v-html="form.errors.get('title')" />
            </div>
        </div>

        <div class="mb-5">
            <label class="block text-sm font-medium text-gray-700">Where to send the submissions</label>
            <div class="mt-1">
                <input type="email"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" v-model="form.send_to">
               <div class="mt-2 text-red-500" v-if="form.errors.has('send_to')" v-html="form.errors.get('send_to')" />
            </div>
        </div>

         <button :disabled="form.busy" type="submit" class="mb-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        {{(form.busy) ? 'Please wait...': 'Save form'}}
    </button>

    </form>
</template>

<script>
import {Form} from 'vform'

export default {
    name: '',
    data(){
        return {
            form: new Form({
                title: '',
                send_to: ''
            })
        }
    },
    created(){
        if(this.$route.name == 'Form'){
            this.getForm();
        }
    },
    methods: {
        sendForm(){
            if(this.$route.name == 'Form'){
                return this.updateForm();
            }

            return this.createForm();
            
        },
        createForm(){
            this.form.post('/form').then((response)=>{
                console.log(response);
                this.$router.push({name:'Dashboard'});
            });
        },
        updateForm(){
              this.form.put('/form/'+this.$route.params.id).then((response)=>{
                console.log(response);
            });
        },
        getForm(){
            this.$axios.get('/form/'+this.$route.params.id).then((response)=>{
                this.form.fill(response.data);
            }); 
        }
    }
}
</script>
