<template>
    <admin-ui>
                <template v-slot:title>
   New Form
  </template> 

        <form-partials />



    </admin-ui>
</template>

<script>
import FormPartials from '@/views/Partials/Form-partials'
import adminUi from '@/components/admin-ui'
export default {
    components: {adminUi,FormPartials}
}
</script>
