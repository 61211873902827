import { render, staticRenderFns } from "./Form-partials.vue?vue&type=template&id=54e303c7&"
import script from "./Form-partials.vue?vue&type=script&lang=js&"
export * from "./Form-partials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports